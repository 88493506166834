:root {
	--spacing-tight: 8px;
	--spacing-base: 16px;
	--spacing-loose: 20px;
	--spacing-extra-loose: 32px;
}

.timeline {
	position: relative;
	margin-left: var(--spacing-tight);
	margin-top: 20px;
}

.verticalLine {
	width: 2px;
	background-color: var(--p-border-subdued);
	position: absolute;
	/* Date text height (16px) + margin between text and first dot (32px) + half of dot height (8px) = 56px */
	top: 56px;
	/* Half of dot's height = 8px */
	bottom: 8px;
	left: 0;
}

.day {
	padding-left: var(--spacing-loose);
	margin-bottom: var(--spacing-tight);
	margin-top: var(--spacing-tight);
}

.day:first-of-type {
	margin-top: var(--spacing-loose);
}

.event {
	position: relative;
	padding-left: var(--spacing-loose);
}

.event:after {
	position: absolute;
	display: block;
	left: -7px;
	background: var(--p-icon-subdued);
	border: 2px solid var(--p-border-subdued);
	border-radius: 50%;
	height: 16px;
	width: 16px;
	content: '';
	top: 3px;
}

.event:not(:last-of-type) {
	margin-bottom: var(--spacing-extra-loose);
}

.event:not(:first-of-type) {
	margin-top: var(--spacing-loose);
}

[data-variation='negative']:after {
	background: var(--p-interactive-critical-hovered);
}

[data-variation='positive']:after {
	background: var(--p-text-success);
}

[data-expandable='true'] {
	cursor: pointer;
}

.eventHeader {
	display: flex;
	justify-content: space-between;
}

.eventTitle {
	display: flex;
	position: relative;
}

.eventTitleWithIcon {
	padding-right: 1.5em;
}

.eventTitleTimestamp {
	white-space: nowrap;
	margin-left: var(--spacing-base);
	padding-left: 1rem;
}

.titleIcon {
	margin-left: var(--spacing-tight);
	position: absolute;
}

[aria-expanded='false'] .titleIcon {
	transform: rotate(270deg);
}

.eventButton {
	margin-top: var(--spacing-base);
}

.eventDetails {
	margin-top: var(--spacing-base);
}

.eventDetail {
	display: flex;
}

.detailLabel {
	margin-right: 0.5em;
}
