.config {
	border-collapse: collapse;
}

.config th,
.config td {
	padding: 0.25em 1.5em 0.25em 0;
	text-align: left;
	vertical-align: top;
}
