.subduedSection {
	background: #f1f2f3;
	padding: 10px 20px;
}

.productColumns {
	display: grid;
	grid-template-columns: 1fr 2fr;
	grid-gap: 2rem;
	align-items: center;
}
