.container {
	margin-top: 100px;
	width: 350px;
}

.container h1 {
	text-align: center;
	font-size: 2.4rem;
	line-height: 3rem;
	margin-bottom: 2rem;
}

.logo {
	width: 60px;
	height: 60px;
	display: block;
	margin: 0 auto;
}

.version {
	padding: 2em;
	text-align: center;
	color: #999;
}
