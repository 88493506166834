.DropZoneHeader {
	display: flex;
	justify-content: space-between;
}

.DropZoneSpinnerContainer {
	display: flex;
	height: 100%;
	justify-content: center;
	align-items: center;
}

.DropZoneImage {
	padding: 2rem;
}
