.feedbackLinkContainer {
	display: flex;
	flex: 1;
	justify-content: flex-start;
	align-items: flex-end;
	padding-bottom: 60px;
}

.feedbackLinkContainer > a {
	--p-action-primary: rgba(0, 128, 96, 1);
	--p-action-primary-disabled: rgba(241, 241, 241, 1);
	--p-action-primary-hovered: rgba(0, 110, 82, 1);
	--p-action-primary-pressed: rgba(0, 94, 70, 1);
	--p-action-primary-depressed: rgba(0, 61, 44, 1);

	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
}

.version {
	padding: 0 2em 2em 2em;
	max-width: 250px;
	color: #999;
	word-break: break-all;
	line-height: 1.5em;
}
