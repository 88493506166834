.spinner {
	position: fixed;
	left: calc(50% - 22px);
	top: calc(50% - 22px);
	transform: scale(2);
}

@media (max-width: 48.0625em) and (min-width: 30.625em),
	(min-width: 45.625em),
	(min-width: 30.625em) {
	[data-has-navigation] .Polaris-Layout__Section + .Polaris-Layout__AnnotatedSection,
	[data-has-navigation] .Polaris-Layout__AnnotatedSection + .Polaris-Layout__AnnotatedSection {
		padding-top: 1.6rem;
		border-top: none;
	}
}
