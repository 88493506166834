.wideDescriptionListTerm dd,
.wideDescriptionListTerm dt,
.narrowDescriptionList dd,
.narrowDescriptionList dt {
	border: none !important;
	padding: 0.35em 0 !important;
}

@media (min-width: 1000px) {
	[data-has-navigation] .narrowDescriptionList > dl > dt {
		flex: 0 1 15%;
	}

	[data-has-navigation] .narrowDescriptionList > dl > dd {
		flex: 1 1 85%;
	}
}

@media (max-width: 48.0625em) and (min-width: 36.875em), (min-width: 51.875em) {
	[data-has-navigation] .wideDescriptionListTerm > dl > dt {
		flex: 0 1 45%;
		margin-right: 6px;
	}
}

@media (min-width: 36.875em) {
	.wideDescriptionListTerm > dl > dt {
		flex: 0 1 45%;
	}
}
