.container {
	display: block;
	width: 100%;
}

.toggle {
	text-align: right;
	top: -5px;
	position: relative;
}

.flash {
	position: fixed;
	z-index: 100;
	right: 10px;
	bottom: 10px;
	background: var(--p-action-primary);
	color: white;
	padding: 0.25em 0.5em;
	border-radius: 3px;
}
