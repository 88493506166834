.peek {
	margin: -2rem;
	display: inline-flex;
}

.activator {
	position: relative;
	z-index: 2;
	display: inline-flex;
	align-items: center;
	padding: 0.8rem 1.6rem;
	color: inherit;
	text-decoration: none;
	border: 0.1rem solid transparent;
	box-shadow: 0 0 0 0 transparent;
	background-color: initial;
	width: 100%;
	cursor: pointer;
	transition-property: background-color, border-color;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 100ms;
}

.activator::after {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	border: 0.1rem solid transparent;
	border-radius: 3px;
}

.activator:focus {
	outline: 0;
}

.activator:focus > div {
	transition-property: opacity;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 100ms;
	opacity: 1;
}

.activator:focus::after {
	content: '';
	display: block;
	border-color: #5c6ac4;
}

.activator:hover {
	background: rgba(179, 188, 245, 0.1);
}

.activator:hover > div {
	transition-property: opacity;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 100ms;
	opacity: 1 !important;
}

.activator[aria-pressed='true'] {
	background: rgba(179, 188, 245, 0.1);
}

.activator[aria-pressed='true'] > div {
	transition-property: opacity;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 100ms;
	opacity: 1;
}

.activator[aria-pressed='true'] .activatorIcon {
	opacity: 1;
}

.activatorText {
	margin-right: 0.4rem;
}

.activatorIcon {
	opacity: 0;
	transition-property: opacity;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 100ms;
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 10;
}
