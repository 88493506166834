.header {
	font-weight: 600;
	font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue,
		sans-serif;
	text-transform: uppercase;
}

.number {
	font-weight: 600;
	font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue,
		sans-serif;
	font-size: 25px;
}
