.container {
	display: inline-block;
}

.label {
	display: flex;
	align-items: center;
}

.label[data-align='right'] {
	flex-direction: row-reverse;
}

.label[aria-disabled='true'] > span {
	opacity: 0.5;
}

.label {
	cursor: pointer;
}

.label[aria-disabled='true'] {
	cursor: not-allowed;
}

.button {
	width: 24px;
	height: 14px;
	border-radius: 11px;
	padding: 3px 8px;
	color: white;
	background: #ddd;
	position: relative;
	transition: background 100ms linear;
	border: none;
	outline: none;
	margin: 0 0.5em;
	cursor: pointer;
}

.button > span {
	display: inline-block;
	background: white;
	position: absolute;
	vertical-align: middle;
	top: 2px;
	left: 2px;
	border-radius: 50%;
	width: 10px;
	height: 10px;
	transition: left 100ms linear;
}

.button[aria-disabled='true'] {
	cursor: not-allowed;
}

.button[aria-checked='true'] {
	text-align: left;
	background: var(--p-action-primary);
}

.button[aria-checked='true'] > span {
	left: 12px;
}
