.iconBadgeContainer {
	display: flex;
	padding: 0 6px 0 2px;
	background: #ffd79d;
	border-radius: 10px;
}

.iconBadgeContainer svg {
	padding: 3px 0;
}
