.ordersCollected {
	font-size: 20px;
	line-height: 28px;
}

.ordersCollectedHeader {
	font-size: 12px;
	line-height: 16px;
	text-transform: uppercase;
}
