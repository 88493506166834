.attachmentContainer {
	border: 1px solid #c9cccf;
	border-radius: 4px;
	padding-right: 8px;
}

.attachmentContainer:hover {
	cursor: pointer;
}

.iconContainer {
	visibility: hidden;
}

.attachmentContainer:hover .iconContainer {
	visibility: visible;
}

.DropZoneSpinnerContainer {
	display: flex;
	height: 100%;
	justify-content: center;
	align-items: center;
}
