.SpinnerContainer {
	text-align: center;
	padding: 5rem;
}

.EmptyStateContainer {
	padding-bottom: 2rem;
}

.PaginationContainer {
	display: flex;
	justify-content: center;
}

.ViewsContainer {
	padding-bottom: 2rem;
}

.TableContainer tbody th,
.TableContainer tbody td {
	white-space: normal;
}

.TableContainer tbody a,
.TableContainer td > div,
.TableContainer td > span {
	white-space: nowrap;
}
