.trackingLabels {
	min-width: 120px;
}

.trackingLabels div {
	padding: 4px 0;
}

.trackingData div {
	padding: 3px 0;
}
