.relativeDate[data-severity='critical'] {
	color: var(--p-text-critical);
}

.relativeDate[data-severity='warning'] {
	color: var(--p-text-warning);
}

.relativeDate[data-severity='info'] {
	color: var(--p-text-subdued);
}
