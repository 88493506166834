.cardDetails > dl > dt,
.cardDetails > dl > dd {
	border: none !important;
	padding: 0.35em 1em 0.35em 0 !important;
	min-width: 12rem;
}

/* If there's only one dl child */
.cardDetails > dl:first-child:nth-last-child(1) > dt {
	flex: 0 1 17.5% !important;
}

.cardDetails > dl:first-child:nth-last-child(1) > dd {
	/* This is a magic number that makes things work when
	there are multiple elements in a single column */
	flex-basis: 66%;
}

/* If there are 2 dl children */
.cardDetails > dl:first-child:nth-last-child(2) > dt {
	flex: 0 1 35% !important;
}

@media (min-width: 36.875em) {
	.cardDetails {
		display: flex;
		align-items: flex-start;
	}

	.cardDetails > dl {
		/* preserve alignment of columns from one component to another */
		flex: 1 0 50%;
	}
}
