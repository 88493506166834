.IconContainer {
	display: flex;
	margin-left: 1rem;
	visibility: visible;
}

.IconContainerHidden {
	display: flex;
	margin-left: 1rem;
	visibility: hidden;
}
