.svgTextBigGreen {
	font: 11px sans-serif;
	letter-spacing: 0.08em;
	text-transform: uppercase;
	fill: #108043;
}

.svgTextBigGrey {
	font: 10px sans-serif;
	text-transform: uppercase;
	fill: grey;
}

.svgTextSmall {
	font: italic 10px sans-serif;
	fill: grey;
}
