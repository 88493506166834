.tableContainer div div table tbody tr td:first-child div,
.tableContainer div div table thead tr th:first-child div {
	display: none;
}

.iconBadgeContainer {
	justify-content: center;
	align-items: center;
	padding: 2px;
	background: #fed3d1;
	border-radius: 15px;
}

.workLogEmptyStateContainer {
	padding-top: 2rem;
}
